<div class="message chat-info {{{o.extra_classes}}}" data-isodate="{{{o.isodate}}}" {[ if (o.data_name) { ]} data-{{{o.data_name}}}="{{{o.data_value}}}"{[ } ]}>
{[ if (o.render_message) {
    // XXX: Should only ever be rendered if the message text has been sanitized already
]}
    {{o.message}}
{[ } else { ]}
    {{{o.message}}}
{[ } ]}
{[ if (o.retry) { ]}
    <a class="retry">Retry</a>
{[ } ]}
</div>
