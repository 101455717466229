<div class="emoji-picker dropdown-menu toolbar-menu">
    <div class="emoji-picker__header">
        <input class="form-control emoji-search" name="emoji-search" placeholder="{{{o.__('Search')}}}"/>
        {[ if (!o.query) { ]}
        <ul>
            {[ Object.keys(o.emoji_categories).forEach(function (category) { ]}
                {[ if (o.emoji_categories[category]) { ]}
                <li data-category="{{{category}}}" class="emoji-category {{{o.current_category}}} {{{ category}}} {[ if (o.current_category === category) { ]} picked {[ } ]}"
                    title="{{{ o.__(o._converse.emoji_category_labels[category]) }}}">
                    <a class="pick-category" href="#emoji-picker-{{{category}}}" data-category="{{{category}}}"> {{ o.transformCategory(o.emoji_categories[category]) }} </a>
                </li>
                {[ } ]}
            {[ }); ]}
        </ul>
        {[ } ]}
    </div>
    <div class="emoji-picker__lists">
        {[ if (o.query) { ]}
            <a id="emoji-picker-search-results" class="emoji-category__heading">{{{o.__('Search results')}}}</a>
            <ul class="emoji-picker">
                {[ o.search_results.forEach(function (emoji) { ]}
                <li class="emoji insert-emoji {[ if (o.shouldBeHidden(emoji.sn)) { ]} hidden {[ }; ]}"
                    data-emoji="{{{emoji.sn}}}" title="{{{emoji.sn}}}">
                        <a href="#" data-emoji="{{{emoji.sn}}}"> {{ o.transform(emoji.sn) }}  </a>
                </li>
                {[ }); ]}
            </ul>
        {[ } else { ]}
            {[ Object.keys(o.emoji_categories).forEach(function (category) { ]}
                {[ if (o.emoji_categories[category]) { ]}
                    <a id="emoji-picker-{{{category}}}" class="emoji-category__heading" data-category="{{{category}}}">{{{ o.__(o._converse.emoji_category_labels[category]) }}} </a>
                    <ul class="emoji-picker" data-category="{{{category}}}">
                        {[ Object.values(o.emojis_by_category[category]).forEach(function (emoji) { ]}
                        <li class="emoji insert-emoji {[ if (o.shouldBeHidden(emoji.sn)) { ]} hidden {[ }; ]}"
                            data-emoji="{{{emoji.sn}}}" title="{{{emoji.sn}}}">
                                <a href="#" data-emoji="{{{emoji.sn}}}"> {{ o.transform(emoji.sn) }}  </a>
                        </li>
                        {[ }); ]}
                    </ul>
                {[ } ]}
            {[ }); ]}
        {[ } ]}
    </div>
    <div class="emoji-skintone-picker">
        <label>Skin tone</label>
        <ul>
            {[ o.skintones.forEach(function (skintone) { ]}
                <li data-skintone="{{{skintone}}}" class="emoji-skintone {[ if (o.current_skintone === skintone) { ]} picked {[ } ]}">
                    <a class="pick-skintone" href="#" data-skintone="{{{skintone}}}"> {{ o.transform(':'+skintone+':') }} </a>
                </li>
            {[ }); ]}
        </ul>
    </div>
</div>
