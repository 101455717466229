<vCard xmlns="vcard-temp">
    <FN>{{{o.fn}}}</FN>
    <NICKNAME>{{{o.nickname}}}</NICKNAME>
    <URL>{{{o.url}}}</URL>
    <ROLE>{{{o.role}}}</ROLE>
    <EMAIL><INTERNET/><PREF/><USERID>{{{o.email}}}</USERID></EMAIL>
    <PHOTO>
      <TYPE>{{{o.image_type}}}</TYPE>
      <BINVAL>{{{o.image}}}</BINVAL>
    </PHOTO>
</vCard>
