<div class="form-group">
    {[ if (o.type !== 'hidden') { ]}
        <label for="{{{o.id}}}">{{{o.label}}}</label>
    {[ } ]}
    {[ if (o.type === 'password' && o.fixed_username) { ]}
        <!-- This is a hack to prevent Chrome from auto-filling the username in
            any of the other input fields in the MUC configuration form. -->
        <input class="hidden-username" type="text" autocomplete="username" value="{{{o.fixed_username}}}"></input>
    {[ } ]}
    <input 
        class="form-control" name="{{{o.name}}}" type="{{{o.type}}}" id="{{{o.id}}}"
        {[ if (o.autocomplete) { ]} autocomplete="{{{o.autocomplete}}}" {[ } ]}
        {[ if (o.placeholder) { ]} placeholder="{{{o.placeholder}}}" {[ } ]}
        {[ if (o.value) { ]} value="{{{o.value}}}" {[ } ]}
        {[ if (o.required) { ]} required="required" {[ } ]} />
</div>
