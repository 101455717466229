{[ if (o.show_call_button)  { ]}
<li class="toggle-call fa fa-phone" title="{{{o.label_start_call}}}"></li>
{[ } ]}
{[ if (o.show_occupants_toggle)  { ]}
<li class="toggle-occupants float-right fa {[ if (o.hidden_occupants)  { ]} fa-angle-double-left {[ } else { ]} fa-angle-double-right {[ } ]}"
    title="{{{o.label_hide_occupants}}}"></li>
{[ } ]}
{[ if (o.message_limit)  { ]}
<li class="message-limit font-weight-bold float-right" title="{{{o.label_message_limit}}}">{{{o.message_limit}}}</li>
{[ } ]}
