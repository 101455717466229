<!-- FIXME: check markup in mockup -->
<div class="room-info">
<p class="room-info"><strong>{{{o.label_jid}}}</strong> {{{o.jid}}}</p>
<p class="room-info"><strong>{{{o.label_desc}}}</strong> {{{o.desc}}}</p>
<p class="room-info"><strong>{{{o.label_occ}}}</strong> {{{o.occ}}}</p>
<p class="room-info"><strong>{{{o.label_features}}}</strong>
    <ul>
        {[ if (o.passwordprotected) { ]}
        <li class="room-info locked">{{{o.label_requires_auth}}}</li>
        {[ } ]}
        {[ if (o.hidden) { ]}
        <li class="room-info">{{{o.label_hidden}}}</li>
        {[ } ]}
        {[ if (o.membersonly) { ]}
        <li class="room-info">{{{o.label_requires_invite}}}</li>
        {[ } ]}
        {[ if (o.moderated) { ]}
        <li class="room-info">{{{o.label_moderated}}}</li>
        {[ } ]}
        {[ if (o.nonanonymous) { ]}
        <li class="room-info">{{{o.label_non_anon}}}</li>
        {[ } ]}
        {[ if (o.open) { ]}
        <li class="room-info">{{{o.label_open_room}}}</li>
        {[ } ]}
        {[ if (o.persistent) { ]}
        <li class="room-info">{{{o.label_permanent_room}}}</li>
        {[ } ]}
        {[ if (o.publicroom) { ]}
        <li class="room-info">{{{o.label_public}}}</li>
        {[ } ]}
        {[ if (o.semianonymous) { ]}
        <li class="room-info">{{{o.label_semi_anon}}}</li>
        {[ } ]}
        {[ if (o.temporary) { ]}
        <li class="room-info">{{{o.label_temp_room}}}</li>
        {[ } ]}
        {[ if (o.unmoderated) { ]}
        <li class="room-info">{{{o.label_unmoderated}}}</li>
        {[ } ]}
    </ul>
</p>
</div>
