<!-- Change status Modal -->
<div class="modal" id="modal-status-change" tabindex="-1" role="dialog" aria-labelledby="changeStatusModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="changeStatusModalLabel">{{{o.modal_title}}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="{{{o.label_close}}}">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <form class="converse-form set-xmpp-status" id="set-xmpp-status">
                    <div class="form-group">
                        <div class="custom-control custom-radio">
                            <input {[ if (o.status === 'online') { ]} checked="checked" {[ } ]}
                                   type="radio" id="radio-online" value="online" name="chat_status" class="custom-control-input"/>
                            <label class="custom-control-label" for="radio-online">
                                <span class="fa fa-circle chat-status chat-status--online"></span>{{{o.label_online}}}</label>
                        </div>
                        <div class="custom-control custom-radio">
                            <input {[ if (o.status === 'busy') { ]} checked="checked" {[ } ]}
                                   type="radio" id="radio-busy" value="dnd" name="chat_status" class="custom-control-input"/>
                            <label class="custom-control-label" for="radio-busy">
                                <span class="fa fa-minus-circle  chat-status chat-status--busy"></span>{{{o.label_busy}}}</label>
                        </div>
                        <div class="custom-control custom-radio">
                            <input {[ if (o.status === 'away') { ]} checked="checked" {[ } ]}
                                   type="radio" id="radio-away" value="away" name="chat_status" class="custom-control-input"/>
                            <label class="custom-control-label" for="radio-away">
                                <span class="fa fa-circle chat-status chat-status--away"></span>{{{o.label_away}}}</label>
                        </div>
                        <div class="custom-control custom-radio">
                            <input {[ if (o.status === 'xa') { ]} checked="checked" {[ } ]}
                                   type="radio" id="radio-xa" value="xa" name="chat_status" class="custom-control-input"/>
                            <label class="custom-control-label" for="radio-xa">
                                <span class="far fa-circle chat-status chat-status--xa"></span>{{{o.label_xa}}}</label>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="btn-group w-100">
                            <input name="status_message" type="text" class="form-control" 
                                value="{{{o.status_message}}}" placeholder="{{{o.placeholder_status_message}}}"/>
                            <span class="clear-input fa fa-times {[ if (!o.status_message) { ]} hidden {[ } ]}"></span>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary">{{{o.label_save}}}</button>
                </form>
            </div>
        </div>
    </div>
</div>
