<div class="userinfo controlbox-padded">
<div class="controlbox-section profile d-flex">
    <a class="show-profile" href="#">
        <canvas class="avatar align-self-center" height="40" width="40"></canvas>
    </a>
    <span class="username w-100 align-self-center">{{{o.fullname}}}</span>
    {[ if (o._converse.show_client_info) { ]}
        <a class="controlbox-heading__btn show-client-info fa fa-info-circle align-self-center" title="{{{o.info_details}}}"></a>
    {[ } ]}
    {[ if (o._converse.allow_logout) { ]}
        <a class="controlbox-heading__btn logout fa fa-sign-out-alt align-self-center" title="{{{o.title_log_out}}}"></a>
    {[ } ]}
</div>
<div class="d-flex xmpp-status">
    <a class="change-status" title="{{{o.title_change_status}}}" data-toggle="modal" data-target="#changeStatusModal">
        <span class="{{{o.chat_status}}} w-100 align-self-center" data-value="{{{o.chat_status}}}">
            <span class="
                {[ if (o.chat_status === 'online') { ]} fa fa-circle chat-status chat-status--online{[ } ]}
                {[ if (o.chat_status === 'dnd') { ]} fa fa-minus-circle chat-status chat-status--busy {[ } ]}
                {[ if (o.chat_status === 'away') { ]} fa fa-circle chat-status chat-status--away{[ } ]}
                {[ if (o.chat_status === 'xa') { ]} far fa-circle chat-status chat-status--xa {[ } ]}
                {[ if (o.chat_status === 'offline') { ]} fa fa-circle chat-status chat-status--offline{[ } ]}"></span> {{{o.status_message}}}</span>
    </a>
</div>
</div>
