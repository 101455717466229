<div class="chatroom-form-container muc-config-form">
    <form class="converse-form chatroom-form" autocomplete="off">
        <fieldset class="form-group">
            <legend>{{{o.title}}}</legend>
            {[ if (o.title !== o.instructions) { ]}
                <p class="form-help">{{{o.instructions}}}</p>
            {[ } ]}
            <!-- Fields are generated internally, with xForm2webForm -->
            {[ o.fields.forEach(function (field) { ]} {{ field }} {[ }) ]}
        </fieldset>
        <fieldset>
            <input type="submit" class="btn btn-primary" value="{{{o.__('Save')}}}"/>
            <input type="button" class="btn btn-secondary .button-cancel" value="{{{o.__('Cancel')}}}"/>
        </fieldset>
    </form>
</div>
