<div class="modal" id="list-chatrooms-modal" tabindex="-1" role="dialog" aria-labelledby="list-chatrooms-modal-label" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"
                    id="list-chatrooms-modal-label">{{{o.heading_list_chatrooms}}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body d-flex flex-column">
                {[ if (o.show_form) { ]}
                <form class="converse-form list-chatrooms">
                    <div class="form-group">
                        <label for="chatroom">{{{o.label_server_address}}}:</label>
                        <input type="text" value="{{{o.muc_domain}}}" required="required" name="server" class="form-control" placeholder="{{{o.server_placeholder}}}"/>
                    </div>
                    <input type="submit" class="btn btn-primary" name="list" value="{{{o.label_query}}}"/>
                </form>
                {[ } ]}
                <ul class="available-chatrooms list-group"></ul>
            </div>
        </div>
    </div>
</div>
