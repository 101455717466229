<div class="chat-head chat-head-chatbox row no-gutters d-none">
    <div class="chatbox-title">
        <div class="chatbox-title--row">
            {[ if (!o._converse.singleton) { ]}
                <div class="chatbox-navback"><i class="fa fa-arrow-left"></i></div>
            {[ } ]}
            {[ if (o.type !== o._converse.HEADLINES_TYPE) { ]}
                <div> <canvas class="avatar" height="36" width="36"></canvas> </div>
            {[ } ]}
            <div class="chatbox-title__text" title="{{{o.jid}}}">
                {[ if (o.url) { ]} <a href="{{{o.url}}}" target="_blank" rel="noopener" class="user"> {[ } ]}
                    {{{ o.display_name }}}
                {[ if (o.url) { ]} </a> {[ } ]}
            </div>
        </div>
        <div class="chatbox-title__buttons row no-gutters">
            <a class="chatbox-btn close-chatbox-button fa fa-times" title="{{{o.info_close}}}"></a>
            <a class="chatbox-btn show-user-details-modal fa fa-id-card" title="{{{o.info_details}}}"></a>
        </div>
    </div>
    <p class="chat-head__desc">{{{ o.status }}}</p>
</div>
