<div class="form-group">
    {[ if (o.label) { ]}
    <label>
        {{{o.label}}}
    </label>
    {[ } ]}
    <div class="input-group">
        <div class="input-group-prepend">
            <input name="{{{o.name}}}" type="{{{o.type}}}"
                {[ if (o.value) { ]} value="{{{o.value}}}" {[ } ]}
                {[ if (o.required) { ]} required="required" {[ } ]} />
            <div class="input-group-text col" title="{{{o.domain}}}">{{{o.domain}}}</div>
        </div>
    </div>
</div>
