<div class="modal" id="user-profile-modal" tabindex="-1" role="dialog" aria-labelledby="user-profile-modal-label" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="user-profile-modal-label">{{{o.heading_profile}}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="{{{o.label_close}}}"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
                {[ if (o._converse.pluggable.plugins['converse-omemo'].enabled(o._converse)) { ]}
                <ul class="nav nav-pills justify-content-center">
                    <li role="presentation" class="nav-item">
                        <a class="nav-link active" id="profile-tab" href="#profile-tabpanel" aria-controls="profile-tabpanel" role="tab" data-toggle="tab">Profile</a>
                    </li>
                    <li role="presentation" class="nav-item">
                        <a class="nav-link" id="omemo-tab" href="#omemo-tabpanel" aria-controls="omemo-tabpanel" role="tab" data-toggle="tab">OMEMO</a>
                    </li>
                </ul>
                {[ } ]}
                <div class="tab-content">
                    <div class="tab-pane active" id="profile-tabpanel" role="tabpanel" aria-labelledby="profile-tab">
                        <form class="converse-form converse-form--modal profile-form" action="#">
                            <div class="row">
                                <div class="col-auto">
                                    <a class="change-avatar" href="#">
                                        {[ if (o.image) { ]}
                                            <img alt="{{{o.alt_avatar}}}" class="img-thumbnail avatar align-self-center" height="100px" width="100px" src="data:{{{o.image_type}}};base64,{{{o.image}}}"/>
                                        {[ } ]}
                                        {[ if (!o.image) { ]}
                                            <canvas class="avatar" height="100px" width="100px"></canvas>
                                        {[ } ]}
                                    </a>
                                    <input class="hidden" name="image" type="file"/>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label class="col-form-label">{{{o.label_jid}}}:</label>
                                        <div>{{{o.jid}}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="vcard-fullname" class="col-form-label">{{{o.label_fullname}}}:</label>
                                <input id="vcard-fullname" type="text" class="form-control" name="fn" value="{{{o.fullname}}}"/>
                            </div>
                            <div class="form-group">
                                <label for="vcard-nickname" class="col-form-label">{{{o.label_nickname}}}:</label>
                                <input id="vcard-nickname" type="text" class="form-control" name="nickname" value="{{{o.nickname}}}"/>
                            </div>
                            <div class="form-group">
                                <label for="vcard-url" class="col-form-label">{{{o.label_url}}}:</label>
                                <input id="vcard-url" type="url" class="form-control" name="url" value="{{{o.url}}}"/>
                            </div>
                            <div class="form-group">
                                <label for="vcard-email" class="col-form-label">{{{o.label_email}}}:</label>
                                <input id="vcard-email" type="email" class="form-control" name="email" value="{{{o.email}}}"/>
                            </div>
                            <div class="form-group">
                                <label for="vcard-role" class="col-form-label">{{{o.label_role}}}:</label>
                                <input id="vcard-role" type="text" class="form-control" name="role" value="{{{o.role}}}" aria-describedby="vcard-role-help"/>
                                <small id="vcard-role-help" class="form-text text-muted">{{{o.label_role_help}}}</small>
                            </div>
                            <hr/>
                            <div class="form-group">
                                <button type="submit" class="save-form btn btn-primary">{{{o.__('Save and close')}}}</button>
                            </div>
                        </form>
                    </div>
                    {[ if (o._converse.pluggable.plugins['converse-omemo'].enabled(o._converse)) { ]}
                        <div class="tab-pane" id="omemo-tabpanel" role="tabpanel" aria-labelledby="omemo-tab">
                            <form class="converse-form fingerprint-removal">
                                <ul class="list-group fingerprints">
                                    <li class="list-group-item active">{{{o.__("This device's OMEMO fingerprint")}}}</li>
                                    <li class="list-group-item">
                                    {[ if (o.view.current_device && o.view.current_device.get('bundle') && o.view.current_device.get('bundle').fingerprint) { ]}
                                        <span class="fingerprint">{{{o.utils.formatFingerprint(o.view.current_device.get('bundle').fingerprint)}}}</span>
                                    {[ } else {]}
                                        <span class="spinner fa fa-spinner centered"/>
                                    {[ } ]}
                                    </li>
                                </ul>
                                <div class="form-group">
                                    <button type="button" class="generate-bundle btn btn-danger">{{{o.__('Generate new keys and fingerprint')}}}</button>
                                </div>

                                {[ if (o.view.other_devices.length) { ]}
                                    <ul class="list-group fingerprints">
                                        <li class="list-group-item nopadding active">
                                            <label>
                                            <input type="checkbox" class="select-all" title="{{{o.__('Select all')}}}"
                                                   aria-label="{{{o.__('Checkbox to select fingerprints of all other OMEMO devices')}}}"/>
                                            {{{o.__('Other OMEMO-enabled devices')}}}
                                            </label>
                                        </li>
                                        {[ o.view.other_devices.forEach(function (device) { ]}
                                            {[ if (device.get('bundle') && device.get('bundle').fingerprint) { ]}
                                            <li class="fingerprint-removal-item list-group-item nopadding">
                                                <label>
                                                <input type="checkbox" value="{{{device.get('id')}}}"
                                                       aria-label="{{{o.__('Checkbox for selecting the following fingerprint')}}}"/>
                                                <span class="fingerprint">{{{o.utils.formatFingerprint(device.get('bundle').fingerprint)}}}</span>
                                                </label>
                                            </li>
                                            {[ } else {]}
                                            <li class="fingerprint-removal-item list-group-item nopadding">
                                                <label>
                                                <input type="checkbox" value="{{{device.get('id')}}}"
                                                       aria-label="{{{o.__('Checkbox for selecting the following fingerprint')}}}"/>
                                                <span>{{{o.__('Device without a fingerprint')}}}</span>
                                                </label>
                                            </li>
                                            {[ } ]}
                                        {[ }); ]}
                                    </ul>
                                    <div class="form-group">
                                        <button type="submit" class="save-form btn btn-primary">{{{o.__('Remove checked devices and close')}}}</button>
                                    </div>
                                {[ } ]}
                            </form>
                        </div>
                    {[ } ]}
                </div>
            </div>
        </div>
    </div>
</div>
