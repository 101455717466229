<div class="chatroom-form-container muc-password-form">
    <form class="converse-form chatroom-form converse-centered-form">
        <fieldset class="form-group">
            <label>{{{o.heading}}}</label>
            <p class="validation-message">{{{o.validation_message}}}</p>
            <input class="hidden-username" type="text" autocomplete="username" value="{{{o.jid}}}"></input>
            <input type="password" name="password" required="required"
                   class="form-control {{o.error_class}}" placeholder="{{{o.label_password}}}"/>
        </fieldset>
        <fieldset class="form-group">
            <input class="btn btn-primary" type="submit" value="{{{o.label_submit}}}"/>
        </fieldset>
    </form>
</div>
