<div class="list-container list-container--openrooms {{{ !o.rooms.length && 'hidden' || '' }}}">

<a href="#" class="list-toggle open-rooms-toggle controlbox-padded" title="{{{o.desc_rooms}}}">
<span class="fa {[ if (o.toggle_state === o._converse.OPENED) { ]} fa-caret-down {[ } else { ]} fa-caret-right {[ } ]}"></span>{{{o.label_rooms}}}</a>

<div class="items-list rooms-list open-rooms-list {{{ o.collapsed && 'collapsed' }}}">
    {[o.rooms.forEach(function (room) { ]}
        <div class="list-item controlbox-padded available-chatroom d-flex flex-row
            {[ if (o.currently_open(room)) { ]} open {[ } ]}
            {[ if (room.get('num_unread_general')) { ]} unread-msgs {[ } ]}"
            data-room-jid="{{{room.get('jid')}}}">

        {[ if (room.get('num_unread')) { ]}
            <span class="list-item-badge badge badge-room-color msgs-indicator">{{{ room.get('num_unread') }}}</span>
        {[ } ]}
        <a class="list-item-link open-room available-room w-100"
            data-room-jid="{{{room.get('jid')}}}"
            title="{{{o.open_title}}}" href="#">{{{room.getDisplayName()}}}</a>

        {[ if (o.allow_bookmarks) { ]}
        <a class="list-item-action fa {[ if (o.bookmarked) { ]} fa-bookmark remove-bookmark button-on {[ } else { ]} add-bookmark fa-bookmark {[ } ]}"
           data-room-jid="{{{room.get('jid')}}}"
           data-bookmark-name="{{{room.getDisplayName()}}}"
           title="{[ if (o.bookmarked) { ]} {{{o.info_remove_bookmark}}} {[ } else { ]} {{{o.info_add_bookmark}}} {[ } ]}"
           href="#"></a>
        {[ } ]}

        <a class="list-item-action room-info fa fa-info-circle"
           data-room-jid="{{{room.get('jid')}}}"
           title="{{{o.info_title}}}" href="#"></a>

        <a class="list-item-action fa fa-sign-out-alt close-room"
           data-room-jid="{{{room.get('jid')}}}"
           data-room-name="{{{room.getDisplayName()}}}"
           title="{{{o.info_leave_room}}}" href="#"></a>

        </div>
    {[ }) ]}
</div>
</div>
