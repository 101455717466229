<form class="controlbox-padded roster-filter-form input-button-group {[ if (!o.visible) { ]} hidden {[ } ]}">
    <div class="form-inline flex-nowrap">
        <div class="filter-by d-flex flex-nowrap">
            <span class="fa fa-user {[ if (o.filter_type === 'contacts') { ]} selected {[ } ]}" data-type="contacts" title="{{{o.title_contact_filter}}}"></span>
            <span class="fa fa-users {[ if (o.filter_type === 'groups') { ]} selected {[ } ]}" data-type="groups" title="{{{o.title_group_filter}}}"></span>
            <span class="fa fa-circle {[ if (o.filter_type === 'state') { ]} selected {[ } ]}" data-type="state" title="{{{o.title_status_filter}}}"></span>
        </div>

        <div class="btn-group">
            <input {[ if (o.filter_text) { ]} value="{{{o.filter_text}}}" {[ } ]}
                class="roster-filter form-control {[ if (o.filter_type === 'state') { ]} hidden {[ } ]}"
                placeholder="{{{o.placeholder}}}"/>
            <span class="clear-input fa fa-times {[ if (!o.filter_text || o.filter_type === 'state') { ]} hidden {[ } ]}"></span>
        </div>

        <select class="form-control state-type {[ if (o.filter_type !== 'state') { ]} hidden {[ } ]}">
            <option value="">{{{o.label_any}}}</option>
            <option {[ if (o.chat_state === 'unread_messages') { ]} selected="selected" {[ } ]}
                value="unread_messages">{{{o.label_unread_messages}}}</option>
            <option {[ if (o.chat_state === 'online') { ]} selected="selected" {[ } ]}
                value="online">{{{o.label_online}}}</option>
            <option {[ if (o.chat_state === 'chat') { ]} selected="selected" {[ } ]}
                value="chat">{{{o.label_chatty}}}</option>
            <option {[ if (o.chat_state === 'dnd') { ]} selected="selected" {[ } ]}
                value="dnd">{{{o.label_busy}}}</option>
            <option {[ if (o.chat_state === 'away') { ]} selected="selected" {[ } ]}
                value="away">{{{o.label_away}}}</option>
            <option {[ if (o.chat_state === 'xa') { ]} selected="selected" {[ } ]}
                value="xa">{{{o.label_xa}}}</option>
            <option {[ if (o.chat_state === 'offline') { ]} selected="selected" {[ } ]}
                value="offline">{{{o.label_offline}}}</option>
        </select>
    </div>
</form>
