<div class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header {{{o.level}}}">
        <h5 class="modal-title">{{{o.title}}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
          <form class="converse-form converse-form--modal confirm" action="#">
            <div class="form-group">
                {[o.messages.forEach(function (message) { ]}
                    <p>{{{message}}}</p>
                {[ }) ]}
            </div>
            {[ if (o.type === 'prompt') { ]}
              <div class="form-group">
                  <input type="text" name="reason" class="form-control" placeholder="{{{o.placeholder}}}"/>
              </div>
            {[ } ]}
            <div class="form-group">
                <button type="submit" class="btn btn-primary">{{{o.__('OK')}}}</button>
                <input type="button" class="btn btn-secondary" data-dismiss="modal" value="{{{o.__('Cancel')}}}"/>
            </div>
        </form>
      </div>
    </div>
  </div>
</div>
