<div class="d-flex controlbox-padded">
    <span class="w-100 controlbox-heading controlbox-heading--contacts">{{{o.heading_contacts}}}</span>
    <a class="controlbox-heading__btn sync-contacts fa fa-sync" title="{{{o.title_sync_contacts}}}"></a>
    {[ if (o.allow_contact_requests) { ]}
        <a class="controlbox-heading__btn add-contact fa fa-user-plus"
           title="{{{o.title_add_contact}}}"
           data-toggle="modal"
           data-target="#add-contact-modal"></a>
    {[ } ]}
</div>

<form class="roster-filter-form"></form>

<div class="list-container roster-contacts"></div>
