<div class="list-container list-container--bookmarks {{{ !o.hidden && 'hidden' || '' }}}">
    <a href="#" class="list-toggle bookmarks-toggle controlbox-padded" title="{{{o.desc_bookmarks}}}">
        <span class="fa {[ if (o.toggle_state === o._converse.OPENED) { ]} fa-caret-down {[ } else { ]} fa-caret-right {[ } ]}">
        </span> {{{o.label_bookmarks}}}</a>

    <div class="items-list bookmarks rooms-list {[ if (o.toggle_state !== o._converse.OPENED) { ]} hidden {[ } ]}">
    {[o.bookmarks.forEach(function (bm) { ]}
        <div class="list-item controlbox-padded room-item available-chatroom d-flex flex-row {[ if (o.is_bookmark_hidden(bm)) { ]} hidden {[ } ]}" data-room-jid="{{{bm.get('jid')}}}">
            <a class="list-item-link open-room w-100" data-room-jid="{{{bm.get('jid')}}}" title="{{{o.open_title}}}" href="#">{{{bm.getDisplayName()}}}</a>
            <a class="list-item-action remove-bookmark fa fa-bookmark align-self-center {[ if (bm.get('bookmarked')) { ]} button-on {[ } ]}"
                data-room-jid="{{{bm.get('jid')}}}" data-bookmark-name="{{{bm.getDisplayName()}}}"
                title="{{{o.info_remove_bookmark}}}" href="#"></a>
        </div>
    {[ }) ]}
    </div>
</div>
